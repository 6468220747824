<template>
    <div class="footnotes-list">
        <ul>
            <li v-for="(footnote, index) in modifiedNotes" :key="index" :id="`footnote-${index + 1}`">
                <RichTextElements :elements="footnote.footnote" :pageContext="pageContext" bodyStyle="serif-large" />
            </li>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    footnotes: {
        type: Array,
        default: () => [],
    },
    ...streamBlock,
});

const modifiedNotes = props.footnotes.map((note, index) => {
    const newNote = JSON.parse(JSON.stringify(note));
    const noteSuperScript = {
        detail: 0,
        format: 64,
        mode: 'normal',
        type: 'text',
        style: '',
        text: index + 1,
        version: 1,
    };
    newNote.footnote.root.children[0].children.unshift(noteSuperScript);
    return newNote;
});
</script>

<style lang="scss" scoped>
.footnotes-list {
    li {
        margin-bottom: 1.2rem;
        padding-top: var(--header-height);
        margin-top: calc(var(--header-height) * -1);
    }
}
</style>
