<template>
    <div>
        <component :is="component" :data="{ ...block }" :size="size" :context="pageContext" />
    </div>
</template>

<script setup>
const props = defineProps({
    block: {
        type: Object,
        default: () => {},
        required: true,
    },
    size: {
        ...sizes,
    },
    pageContext: {
        type: String,
    },
});

const component = computed(() => {
    const blockType = props.block?.blockType;
    if (blockType) {
        return defineAsyncComponent({
            loader: () =>
                import(`./Stream${blockType.charAt(0).toUpperCase()}${blockType.slice(1)}.vue`).catch((error) => {
                    console.error(
                        `Failed to load block Stream${blockType.charAt(0).toUpperCase()}${blockType.slice(1)}`,
                        error,
                    );
                }),
        });
    }
});
</script>
