export const getRichTextBlockFootnotes = (richTextBlock, idOffset) => {
    let footnoteId = idOffset || 0;
    const newRichTextBlock = JSON.parse(JSON.stringify(richTextBlock));

    const rootNodes = newRichTextBlock.richText.root.children;

    const blockFootnotes = [];

    const addIds = (nodes) => {
        nodes.forEach((node) => {
            if (node.children) {
                addIds(node.children);
            }
            if (node.type === 'footnote') {
                blockFootnotes.push(node);
                footnoteId += 1;
                node.footnoteId = footnoteId;
            }
        });
    };
    addIds(rootNodes);

    return { newRichTextBlock, blockFootnotes, footnoteCount: blockFootnotes.length };
};
export const getStreamBlocksWithFootnotes = (blocks, idOffset) => {
    const allFootnotes = [];
    let lastFootnoteCount = idOffset || 0;

    const processedBlocks = blocks.map((block) => {
        if (block.blockType === 'richTextBlock') {
            const { newRichTextBlock, blockFootnotes, footnoteCount } = getRichTextBlockFootnotes(
                block,
                lastFootnoteCount,
            );
            lastFootnoteCount += footnoteCount;

            if (blockFootnotes.length) {
                blockFootnotes.map((note) => allFootnotes.push(note));
            }

            return newRichTextBlock;
        }
        return block;
    });
    return { processedBlocks, footnotes: allFootnotes, footnoteCount: allFootnotes.length };
};
